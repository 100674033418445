































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { SizeOptionGroup, ISizeOptionGroup } from "@/model/SizeOptionGroup";
import { VForm } from "@/vForm";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class SizeOptionGroupDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("groupForm") private readonly groupForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private group: SizeOptionGroup = new SizeOptionGroup();

    //
    // -- Watchers
    //

    @Watch("group.isContract")
    private onIsContractChanged() {
        if (this.group.isContract) {
            this.group.isStock = false;
        } else {
            this.group.isStock = true;
        }
    }

    //
    // -- methods
    //

    add(): void {
        this.reset();
        this.group.isStock = true;
        this.dialogueTitle = "Add New Size Option Group";
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Size Option Group";
        const groupData: ISizeOptionGroup = await apiClient.get(`api/size/LoadGroup?id=${id}`);
        this.group.update(groupData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.group);
        this.groupForm?.resetValidation();
    }

    private async save() {
        if (!this.groupForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/size/saveGroup", this.group, "group-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
