

























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import SizeOptionsSearchPage from "@/vue/components/sizePages/SizeOptionsSearchPage.vue";
import SizeOptionGroups from "@/vue/components/sizePages/SizeOptionGroups.vue";

@Component({
    components: { 
        ApiButton,
        SizeOptionsSearchPage,
        SizeOptionGroups
    }
})

export default class SizeOptions extends Vue {


}

