

































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import SizeOptionGroupDialogue from "@/vue/components/dialogues/SizeOptionGroupDialogue.vue";
import utils from "@/utilities/Utils";
import { SizeOptionGroup, ISizeOptionGroup } from "@/model/SizeOptionGroup";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        SizeOptionGroupDialogue
    }
})

export default class SizeOptionGroups extends Vue {
    
    //
    // -- properties
    //

    private groupHeaders = [
        { text: "Description", value: "description", sortable: true },
        { text: "Is Stock", value: "isStock", sortable: true },
        { text: "Is Contract", value: "isContract", sortable: true },
        { text: "Is Hidden", value: "isHidden", sortable: true },
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private groupList: Array<SizeOptionGroup> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    addNew(): void {
        const dialog: SizeOptionGroupDialogue = this.$refs.sizeOptionGroupDialogue as SizeOptionGroupDialogue;
        dialog.add();
    }

    edit(group: ISizeOptionGroup): void {
        const dialog: SizeOptionGroupDialogue = this.$refs.sizeOptionGroupDialogue as SizeOptionGroupDialogue;
        dialog.edit(group.id);
    }

    refreshSearch(): void {
        this.groupList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/size/searchGroups", this.searchParameters);
        if (response.list.length) {
            this.groupList.push(...response.list.map((sg: ISizeOptionGroup) => new SizeOptionGroup(sg)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
