import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ISizeOptionGroup {
    id: string;
    description: string;
    isStock: boolean;
    isContract: boolean;
    isHidden: boolean;
    created: Date;
    createdByUser: string;
    lastUpdated: Date;
    lastUpdatedByUser: string;
}

export class SizeOptionGroup implements ISizeOptionGroup {

    constructor(data?: ISizeOptionGroup) {
        if (data) this.update(data);
    }

    update(data: ISizeOptionGroup): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    description: string = "";
    isStock: boolean = false;
    isContract: boolean = false;
    isHidden: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUser: string = "";
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUser: string = "";
}
