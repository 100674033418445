





































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { SizeOption, ISizeOption } from "@/model/SizeOption";
import { SizeOptionGroup } from "@/model/SizeOptionGroup";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class SizeOptionDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("sizeForm") private readonly sizeForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private size: SizeOption = new SizeOption();
    private groups: Array<SizeOptionGroup> = [];

    //
    // -- methods
    //

    async loadGroups(id: string): Promise<void> {
        this.groups = [];
        const response = await apiClient.get(`/api/size/loadGroups?id=${id}`);
        this.groups.push(...response);
    }

    add(): void {
        this.reset();
        this.loadGroups(utils.emptyGuidValue);
        this.dialogueTitle = "Add New Size Option";
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Size Option";
        this.loadGroups(id);
        const sizeData: ISizeOption = await apiClient.get(`api/size/LoadSize?id=${id}`);
        this.size.update(sizeData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.size);
        this.sizeForm?.resetValidation();
    }

    private async save() {
        if (!this.sizeForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/size/saveSize", this.size, "size-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
