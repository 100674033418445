import { ISizeOption, SizeOption } from "../../SizeOption";
import { mapData } from "@/utilities/DataMapping";

export interface ISizeOptionVM {
    sizeOption: ISizeOption;
    groupDescription: string;
    createdByUser: string;
    lastUpdatedByUser: string;
}

export class SizeOptionVM implements ISizeOptionVM {

    constructor(data?: ISizeOptionVM) {
        if (data) this.update(data);
    }

    update(data: ISizeOptionVM): void {
        mapData(data, { 
            root: () => this,
            sizeOption: () => new SizeOption()
        });
    }

    sizeOption: SizeOption = new SizeOption();
    groupDescription: string = "";
    createdByUser: string = "";
    lastUpdatedByUser: string = "";
}
