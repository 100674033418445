




























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import SizeOptionDialogue from "@/vue/components/dialogues/SizeOptionDialogue.vue";
import utils from "@/utilities/Utils";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { SizeOptionGroup } from "@/model/SizeOptionGroup";
import { SizeOptionVM, ISizeOptionVM } from "@/model/Api/VM/SizeOptionVM";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        SizeOptionDialogue
    }
})

export default class SizeOptionsSearchPage extends Vue {

    async mounted(): Promise<void> { 
        this.loadGroups(utils.emptyGuidValue);
    }
    
    //
    // -- properties
    //

    private sizeHeaders = [
        { text: "Group", value: "groupDescription", sortable: true },
        { text: "Contract Size", value: "sizeOption.contractSize", sortable: true },
        { text: "UK", value: "sizeOption.uk", align: "center", sortable: false },
        { text: "EU", value: "sizeOption.eu", align: "center", sortable: false },
        { text: "EU-FR", value: "sizeOption.euFr", align: "center", sortable: false },
        { text: "EU-DE", value: "sizeOption.euDe", align: "center", sortable: false },
        { text: "USA/CA", value: "sizeOption.usaCa", align: "center", sortable: false },
        { text: "AUS/NZ", value: "sizeOption.ausNz", align: "center", sortable: false },
        { text: "Is Hidden", value: "sizeOption.isHidden", align: "center", sortable: true },
        { text: "Created", value: "sizeOption.created", sortable: true },
        { text: "Last Updated", value: "sizeOption.lastUpdated", sortable: true },
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private sizeList: Array<SizeOptionVM> = [];
    private groups: Array<SizeOptionGroup> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.id")
    private onIDChanged() {
        if (this.searchParameters.id == undefined) {
            this.searchParameters.id = utils.emptyGuidValue;
        }
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //
    
    async loadGroups(id: string): Promise<void> {
        this.groups = [];
        const response = await apiClient.get(`/api/size/loadGroups?id=${id}`);
        this.groups.push(...response);
    }

    addNew(): void {
        const dialog: SizeOptionDialogue = this.$refs.sizeOptionDialogue as SizeOptionDialogue;
        dialog.add();
    }

    edit(size: ISizeOptionVM): void {
        const dialog: SizeOptionDialogue = this.$refs.sizeOptionDialogue as SizeOptionDialogue;
        dialog.edit(size.sizeOption.id);
    }

    refreshSearch(): void {
        this.sizeList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/size/searchSizes", this.searchParameters);
        if (response.list.length) {
            this.sizeList.push(...response.list.map((s: ISizeOptionVM) => new SizeOptionVM(s)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
