import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ISizeOption {
    id: string;
    groupID: string;
    contractSize: string;
    uk: string;
    eu: string;
    euFr: string;
    euDe: string;
    usaCa: string;
    ausNz: string;
    isHidden: boolean;
    created: Date;
    createdByUserID: string;
    lastUpdated: Date;
    lastUpdatedByUserID: string;
}

export class SizeOption implements ISizeOption {

    constructor(data?: ISizeOption) {
        if (data) this.update(data);
    }

    update(data: ISizeOption): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    groupID: string = utils.emptyGuidValue;
    contractSize: string = "";
    uk: string = "";
    eu: string = "";
    euFr: string = "";
    euDe: string = "";
    usaCa: string = "";
    ausNz: string = "";
    isHidden: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: string = utils.emptyGuidValue;
}
